import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = {
  key: 0,
  class: "row justify-center q-mt-lg",
  style: {"width":"100%"}
}
const _hoisted_3 = ["title"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["title"]
const _hoisted_6 = {
  key: 0,
  class: "row justify-center q-mt-lg",
  style: {"width":"100%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_p_cta = _resolveComponent("p-cta")!
  const _component_p_products_element = _resolveComponent("p-products-element")!

  return (['products'].includes(_ctx.type))
    ? (_openBlock(), _createBlock(_component_p_products_element, {
        key: 0,
        headline: _ctx.data.title,
        link: _ctx.$utf8.encode(_ctx.data.title !== null ? _ctx.data.title : '')
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["full-width no-wrap", {
                    reverse: _ctx.data.imageLeft,
                    'row': _ctx.$q.screen.gt.sm,
                    'column': _ctx.$q.screen.lt.md
                }])
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["column justify-between items-center", {
                        'q-ml-lg': _ctx.data.imageLeft && _ctx.$q.screen.gt.sm,
                        'q-mr-lg': !_ctx.data.imageLeft && _ctx.$q.screen.gt.sm,
                        'q-mb-md': _ctx.data.imageLeft && !_ctx.$q.screen.gt.sm,
                        'q-mt-md': !_ctx.data.imageLeft && !_ctx.$q.screen.gt.sm,
                    }]),
              style: {"flex":"1"}
            }, [
              _createElementVNode("div", {
                innerHTML: _ctx.data.content
              }, null, 8, _hoisted_1),
              ((_ctx.data.cta || [{ data:{ __typename: '' } }])[0].data.__typename !== '')
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_p_cta, {
                      type: _ctx.type,
                      data: (_ctx.data.cta || [{ data:{ __typename: '' } }])[0].data
                    }, null, 8, ["type", "data"])
                  ]))
                : _createCommentVNode("", true)
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["shadow-8", {
                        'q-ml-lg': !_ctx.data.imageLeft && _ctx.$q.screen.gt.sm,
                        'q-mr-lg': _ctx.data.imageLeft && _ctx.$q.screen.gt.sm,
                        'q-mb-md': _ctx.data.imageLeft && !_ctx.$q.screen.gt.sm,
                        'q-mt-md': !_ctx.data.imageLeft && !_ctx.$q.screen.gt.sm,
                    }]),
              title: (_ctx.data.image || [{ alt: '' }])[0].alt || '',
              style: _normalizeStyle(`
                        flex: 2;
                        background-position: ${_ctx.$cms_to_page_focus((_ctx.data.image || [{ focusX: 0 }])[0].focusX || 0, (_ctx.data.image || [{ focusY: 0 }])[0].focusY || 0)};
                        background-size: cover;
                        background-image: url(
                            ${(_ctx.data.image || [{ url: '' }])[0].url || ''}${
                                (_ctx.data.image || [{ imageQuality: 0 }])[0].imageQuality ?
                                '?quality=' + (_ctx.data.image || [{ imageQuality: 0 }])[0].imageQuality :
                                ''
                            }
                        );
                        border-radius: 20px;
                        min-height: ${ _ctx.$q.screen.gt.sm ? 600 : 300 }px
                    `)
            }, null, 14, _hoisted_3)
          ], 2)
        ]),
        _: 1
      }, 8, ["headline", "link"]))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        _createElementVNode("div", {
          class: _normalizeClass(["row full-width no-wrap", {
                reverse: _ctx.data.imageLeft
            }])
        }, [
          _createElementVNode("div", {
            style: {"flex":"1"},
            class: _normalizeClass({
                    'q-ml-lg': _ctx.data.imageLeft,
                    'q-mr-lg': !_ctx.data.imageLeft
                }),
            innerHTML: _ctx.data.content
          }, null, 10, _hoisted_4),
          _createElementVNode("div", {
            title: (_ctx.data.image || [{ alt: '' }])[0].alt || '',
            class: _normalizeClass(["shadow-8", {
                    'q-mr-lg': _ctx.data.imageLeft,
                    'q-ml-lg': !_ctx.data.imageLeft
                }]),
            style: _normalizeStyle(`
                    flex: 2;
                        background-position: ${_ctx.$cms_to_page_focus((_ctx.data.image || [{ focusX: 0 }])[0].focusX || 0, (_ctx.data.image || [{ focusY: 0 }])[0].focusY || 0)};
                        background-size: cover;
                        background-image: url(
                            ${(_ctx.data.image || [{ url: '' }])[0].url || ''}${
                                (_ctx.data.image || [{ imageQuality: 0 }])[0].imageQuality ?
                                '?quality=' + (_ctx.data.image || [{ imageQuality: 0 }])[0].imageQuality :
                                ''
                            }
                        );
                        border-radius: 20px;
                        min-height: ${ _ctx.$q.screen.gt.sm ? 600 : 300 }px
                `)
          }, null, 14, _hoisted_5)
        ], 2),
        ((_ctx.data.cta || [{ data:{ __typename: '' } }])[0].data.__typename !== '')
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_p_cta, {
                type: _ctx.type,
                data: (_ctx.data.cta || [{ data:{ __typename: '' } }])[0].data
              }, null, 8, ["type", "data"])
            ]))
          : _createCommentVNode("", true)
      ], 64))
}