import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "full-width row justify-center" }
const _hoisted_2 = ["title", "width"]
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 0,
  class: "row justify-center q-mt-xl",
  style: {"width":"100%"}
}
const _hoisted_5 = ["title", "width"]
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 0,
  class: "row justify-center q-mt-xl",
  style: {"width":"100%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_p_cta = _resolveComponent("p-cta")!
  const _component_p_products_element = _resolveComponent("p-products-element")!

  return (['products'].includes(_ctx.type))
    ? (_openBlock(), _createBlock(_component_p_products_element, {
        key: 0,
        headline: _ctx.data.title,
        link: _ctx.$utf8.encode(_ctx.data.title !== null ? _ctx.data.title : '')
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("video", {
              title: (_ctx.data.video || [{ alt: '' }])[0].alt || '',
              style: {"border-radius":"20px"},
              controls: "",
              width: `${_ctx.$q.screen.gt.xs ? _ctx.$q.screen.gt.sm ? _ctx.$q.screen.gt.md ? '900' : '700' : '500' : '328'}`
            }, [
              _createElementVNode("source", {
                src: (_ctx.data.video || [{ url: '' }])[0].url,
                type: "video/mp4"
              }, null, 8, _hoisted_3)
            ], 8, _hoisted_2)
          ]),
          ((_ctx.data.cta || [{ data:{ __typename: '' } }])[0].data.__typename !== '')
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_p_cta, {
                  type: _ctx.type,
                  data: (_ctx.data.cta || [{ data:{ __typename: '' } }])[0].data
                }, null, 8, ["type", "data"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["headline", "link"]))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        _createElementVNode("video", {
          title: (_ctx.data.video || [{ alt: '' }])[0].alt || '',
          style: {"border-radius":"20px"},
          controls: "",
          width: `${_ctx.$q.screen.gt.xs ? _ctx.$q.screen.gt.sm ? _ctx.$q.screen.gt.md ? '900' : '700' : '500' : '328'}`
        }, [
          _createElementVNode("source", {
            src: (_ctx.data.video || [{ url: '' }])[0].url,
            type: "video/mp4"
          }, null, 8, _hoisted_6)
        ], 8, _hoisted_5),
        ((_ctx.data.cta || [{ data:{ __typename: '' } }])[0].data.__typename !== '')
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_p_cta, {
                type: _ctx.type,
                data: (_ctx.data.cta || [{ data:{ __typename: '' } }])[0].data
              }, null, 8, ["type", "data"])
            ]))
          : _createCommentVNode("", true)
      ], 64))
}