/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.config.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/



import {Cookies,Meta,Notify,AddressbarColor,LocalStorage} from 'quasar'



export default { config: {"dark":"auto","notify":{},"screen":{"bodyClasses":true},"brand":{"primary":"#050628","pagisto":"#4E5B31","secondary":"#C6A764","tertiary":"#050628","link":"#C6A764","pagistoni_dark":"#533747","pagistoni_light":"#B4869F","positive":"#21BA45","negative":"#ff3860","info":"#31CCEC","warning":"#F2C037","accent":"#fffcff","accent2":"#f0efeb","accent3":"#D6D5C9","text":"#000000","text2":"#202124","text3":"#333333"}},plugins: {Cookies,Meta,Notify,AddressbarColor,LocalStorage} }

