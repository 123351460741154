import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-308bd152"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  controls: "",
  controlsList: "nodownload",
  style: {"width":"100%"}
}
const _hoisted_2 = ["type", "src"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_component_q_card, {
    class: "bg-accent p-card column justify-start items-center no-shadow",
    style: `overflow: hidden; border-radius: 20px`
  }, {
    default: _withCtx(() => [
      _createElementVNode("video", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.videos || [], (video) => {
          return (_openBlock(), _createElementBlock("source", {
            key: video.url,
            type: video.mimeType,
            src: video.url
          }, null, 8, _hoisted_2))
        }), 128))
      ]),
      _createElementVNode("div", {
        class: "q-pa-lg text-text",
        style: {"flex":"1"},
        innerHTML: _ctx.data.content
      }, null, 8, _hoisted_3)
    ]),
    _: 1
  }))
}