import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_tooltip = _resolveComponent("q-tooltip")!

  return (_openBlock(), _createBlock(_component_q_tooltip, {
    class: "text-body2 bg-secondary text-primary",
    anchor: "bottom middle",
    self: "top middle",
    delay: 300
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.data), 1)
    ]),
    _: 1
  }))
}