import { boot } from 'quasar/wrappers'
import { createI18n } from 'vue-i18n'
import messages from 'src/i18n'

const kown_shorts = ['en', 'de']

const get_valid_locale = (language: string | null) => {
    let locale = 'de-de'

    // @ts-ignore
    const fallback_lang = (lang: string) => {
        switch (lang) {
        case 'en-US':
        case 'en-us':
        case 'en':
            return 'en-us'
        case 'de-DE':
        case 'de-de':
        case 'de':
            return 'de-de'
        default:
            const short = lang.slice(0, 2)
            if (!kown_shorts.includes(short)) {
                return 'de-de'
            } else {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                return fallback_lang(short)
            }
        }
    }

    if (!language || language === null) {
        locale = fallback_lang('')
    } else {
        locale = fallback_lang(language)
    }

    return locale
}

const get_short_locale = (language: string | null) => {
    return get_valid_locale(language).toString().split('-')[0].toLowerCase()
}

export default boot(({ app }) => {
    const i18n = createI18n({
        locale: 'de-de',
        messages
    })

    // Set i18n instance on app
    app.use(i18n)
})

export {
    get_valid_locale,
    get_short_locale,
    kown_shorts
}
