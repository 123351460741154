import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"padding":"2% 0"}
}
const _hoisted_2 = ["alt", "title", "src"]
const _hoisted_3 = ["alt", "title", "src"]
const _hoisted_4 = {
  key: 1,
  class: "full-width row justify-center"
}
const _hoisted_5 = ["width", "alt", "title", "src"]
const _hoisted_6 = ["width", "alt", "title", "src"]
const _hoisted_7 = {
  key: 2,
  class: "row justify-center q-mt-md",
  style: {"width":"100%"}
}
const _hoisted_8 = { key: 1 }
const _hoisted_9 = {
  key: 0,
  style: {"padding":"2% 0"}
}
const _hoisted_10 = ["src"]
const _hoisted_11 = ["src"]
const _hoisted_12 = {
  key: 1,
  class: "full-width row justify-center"
}
const _hoisted_13 = ["width", "src"]
const _hoisted_14 = ["width", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_p_cta = _resolveComponent("p-cta")!
  const _component_p_products_element = _resolveComponent("p-products-element")!

  return (['products'].includes(_ctx.type))
    ? (_openBlock(), _createBlock(_component_p_products_element, {
        key: 0,
        headline: _ctx.data.title,
        link: _ctx.$utf8.encode(_ctx.data.title !== null ? _ctx.data.title : ''),
        custom_padding: _ctx.data.fullWidth
      }, {
        default: _withCtx(() => [
          (_ctx.data.fullWidth)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_ctx.$q.dark.isActive)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      style: {"border-radius":"20px"},
                      loading: "lazy",
                      class: "full-width",
                      alt: (_ctx.data.imageDark || [{ alt: '' }])[0].alt || '',
                      title: (_ctx.data.imageDark || [{ alt: '' }])[0].alt || '',
                      src: `
                                ${(_ctx.data.imageDark || []).length === 1 ? (_ctx.data.imageDark || [{ url: '' }])[0].url : (_ctx.data.imageLight || [{ url: '' }])[0].url }
                                ?quality=${(_ctx.data.imageDark || []).length === 1 ? (_ctx.data.imageDark || [{ imageQuality: 0 }])[0].imageQuality || 100 : (_ctx.data.imageLight || [{ imageQuality: 0 }])[0].imageQuality || 100 }
                            `
                    }, null, 8, _hoisted_2))
                  : (_openBlock(), _createElementBlock("img", {
                      key: 1,
                      style: {"border-radius":"20px"},
                      loading: "lazy",
                      class: "full-width",
                      alt: (_ctx.data.imageLight || [{ alt: '' }])[0].alt || '',
                      title: (_ctx.data.imageLight || [{ alt: '' }])[0].alt || '',
                      src: `
                                ${(_ctx.data.imageLight || [{ url: '' }])[0].url || ''}${
                                    (_ctx.data.imageLight || [{ imageQuality: 0 }])[0].imageQuality ?
                                    '?quality=' + (_ctx.data.imageLight || [{ imageQuality: 0 }])[0].imageQuality :
                                    ''
                                }
                            `
                    }, null, 8, _hoisted_3))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_ctx.$q.dark.isActive)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      style: {"border-radius":"20px"},
                      loading: "lazy",
                      width: `${_ctx.$q.screen.gt.xs ? _ctx.$q.screen.gt.sm ? _ctx.$q.screen.gt.md ? '900' : '700' : '500' : '328'}`,
                      alt: (_ctx.data.imageDark || [{ alt: '' }])[0].alt || '',
                      title: (_ctx.data.imageDark || [{ alt: '' }])[0].alt || '',
                      src: `
                                ${(_ctx.data.imageDark || []).length === 1 ? (_ctx.data.imageDark || [{ url: '' }])[0].url : (_ctx.data.imageLight || [{ url: '' }])[0].url }
                                ?quality=${(_ctx.data.imageDark || []).length === 1 ? (_ctx.data.imageDark || [{ imageQuality: 0 }])[0].imageQuality || 100 : (_ctx.data.imageLight || [{ imageQuality: 0 }])[0].imageQuality || 100 }
                            `
                    }, null, 8, _hoisted_5))
                  : (_openBlock(), _createElementBlock("img", {
                      key: 1,
                      style: {"border-radius":"20px"},
                      loading: "lazy",
                      width: `${_ctx.$q.screen.gt.xs ? _ctx.$q.screen.gt.sm ? _ctx.$q.screen.gt.md ? '900' : '700' : '500' : '328'}`,
                      alt: (_ctx.data.imageLight || [{ alt: '' }])[0].alt || '',
                      title: (_ctx.data.imageLight || [{ alt: '' }])[0].alt || '',
                      src: `
                                ${ (_ctx.data.imageLight || [{ url: '' }])[0].url || '' }
                                ?quality=${(_ctx.data.imageLight || [{ imageQuality: 0 }])[0].imageQuality || 100 }
                            `
                    }, null, 8, _hoisted_6))
              ])),
          ((_ctx.data.cta || [{ data:{ __typename: '' } }])[0].data.__typename !== '')
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(_component_p_cta, {
                  type: _ctx.type,
                  data: (_ctx.data.cta || [{ data:{ __typename: '' } }])[0].data
                }, null, 8, ["type", "data"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["headline", "link", "custom_padding"]))
    : (_openBlock(), _createElementBlock("div", _hoisted_8, [
        (_ctx.data.fullWidth)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              (_ctx.$q.dark.isActive)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    loading: "lazy",
                    class: "full-width",
                    src: (_ctx.data.imageDark || []).length === 1 ? (_ctx.data.imageDark || [{ url: '' }])[0].url : (_ctx.data.imageLight || [{ url: '' }])[0].url
                  }, null, 8, _hoisted_10))
                : (_openBlock(), _createElementBlock("img", {
                    key: 1,
                    loading: "lazy",
                    class: "full-width",
                    src: (_ctx.data.imageLight || [{ url: '' }])[0].url
                  }, null, 8, _hoisted_11))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_12, [
              (_ctx.$q.dark.isActive)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    loading: "lazy",
                    width: `${_ctx.$q.screen.gt.xs ? _ctx.$q.screen.gt.sm ? _ctx.$q.screen.gt.md ? '900' : '600' : '328' : '330'}`,
                    src: (_ctx.data.imageDark || []).length === 1 ? (_ctx.data.imageDark || [{ url: '' }])[0].url : (_ctx.data.imageLight || [{ url: '' }])[0].url
                  }, null, 8, _hoisted_13))
                : (_openBlock(), _createElementBlock("img", {
                    key: 1,
                    loading: "lazy",
                    width: `${_ctx.$q.screen.gt.xs ? _ctx.$q.screen.gt.sm ? _ctx.$q.screen.gt.md ? '900' : '600' : '328' : '330'}`,
                    src: (_ctx.data.imageLight || [{ url: '' }])[0].url
                  }, null, 8, _hoisted_14))
            ]))
      ]))
}