
import { defineComponent } from 'vue'
import { CMS_SCHEMA_Calltoactions } from '@/types/cms/calltoactions'

// components
import Tooltip from '@/components/Tooltip.vue'


export default defineComponent({
    name: 'p_components_pages_cta',

    components: {
        'p-tooltip': Tooltip
    },

    props: {
        data: {
            default: new CMS_SCHEMA_Calltoactions()
        },
        type: {
            default: ''
        }
    },

    methods: {
        updated_link (link: string) {
            if (this.$route && this.$route.query && this.$route.query?.a && typeof this.$route.query?.a === 'string') {
                const affiliate = this.$route.query.a

                if (link.search('office77eb9399171147249856a891ffb4ca36') > -1) {
                    return link
                        .split('office77eb9399171147249856a891ffb4ca36')
                        .join(affiliate.toString())
                } else {
                    return link
                }
            } else {
                return link
            }
        }
    }
})
