
import { defineComponent } from 'vue'
import { CMS_COMPONENT_Pageshero2 } from '@/types/cms/pages'

export default defineComponent({
    name: 'p_components_pages_hero',

    props: {
        data: {
            default: new CMS_COMPONENT_Pageshero2()
        },
        source_data: {
            default: [new CMS_COMPONENT_Pageshero2()]
        }
    }
})
