import { boot } from 'quasar/wrappers'

import empty from '@/components/Empty.vue'

//pages
import pages_hero from '@/components/pages/hero/Index.vue'
import pages_hero2 from '@/components/pages/hero2/Index.vue'
import pages_text from '@/components/pages/Text.vue'
import pages_histgramh from '@/components/pages/histrogramh/Index.vue'
import pages_histgramh_steps from '@/components/pages/histrogramh/Step.vue'
import pages_grid from '@/components/pages/grid/Index.vue'
import pages_grid_element_download from '@/components/pages/grid/Download_Element.vue'
import pages_grid_element_link from '@/components/pages/grid/Link_Element.vue'
import pages_grid_element_text from '@/components/pages/grid/Text_Element.vue'
import pages_grid_element_text_icon from '@/components/pages/grid/Text_Icon_Element.vue'
import pages_grid_element_text_image from '@/components/pages/grid/Text_Image_Element.vue'
import pages_grid_element_text_video from '@/components/pages/grid/Text_Video_Element.vue'
import pages_video from '@/components/pages/Video.vue'
import pages_image from '@/components/pages/Image.vue'
import pages_list_grid_combi from '@/components/pages/list_grid_combi/Index.vue'
import pages_grid_list from '@/components/pages/grid_list/Index.vue'
import pages_grid_list_element_icon from '@/components/pages/grid_list/Icon_Element.vue'
import pages_grid_list_element_image from '@/components/pages/grid_list/Image_Element.vue'
import pages_text_image from '@/components/pages/Text_Image.vue'
import pages_links from '@/components/pages/pages/Index.vue'
import pages_pages from '@/components/pages/pages/Page.vue'
import pages_timeline from '@/components/pages/timeline/Index.vue'
import pages_timeline_element_text from '@/components/pages/timeline/Text_Element.vue'


const component_register = (name: string) => {
    switch(name){
    case 'PagesheroComponent':
        return pages_hero
    case 'Pageshero2Component':
        return pages_hero2
    case 'PagestextComponent':
        return pages_text
    case 'PageshistogramhComponent':
        return pages_histgramh
    case 'Histogramsteps':
        return pages_histgramh_steps
    case 'Histogramsteps':
        return pages_histgramh_steps
    case 'PagesgridComponent':
        return pages_grid
    case 'PagesgridelementdownloadComponent':
        return pages_grid_element_download
    case 'PagesgridelementtextComponent':
        return pages_grid_element_text
    case 'PagesgridelementtexticonComponent':
        return pages_grid_element_text_icon
    case 'PagesgridelementtextimageComponent':
        return pages_grid_element_text_image
    case 'PagesgridelementtextvideoComponent':
        return pages_grid_element_text_video
    case 'PagesgridelementlinkComponent':
        return pages_grid_element_link
    case 'PagesvideoComponent':
        return pages_video
    case 'PagesimageComponent':
        return pages_image
    case 'PageslistgridcombiComponent':
        return pages_list_grid_combi
    case 'PagesgridlistComponent':
        return pages_grid_list
    case 'PagesgridlistelementiconComponent':
        return pages_grid_list_element_icon
    case 'PagesgridlistelementimageComponent':
        return pages_grid_list_element_image
    case 'PagestextimageComponent':
        return pages_text_image
    case 'PageslinksComponent':
        return pages_links
    case 'Pages':
        return pages_pages
    case 'PagestimelineComponent':
        return pages_timeline
    case 'PagestimelineelementtextComponent':
        return pages_timeline_element_text
    default:
        return empty
    }
}

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        $component_register: any
    }
}

export default boot(({ app }) => {
    app.config.globalProperties.$component_register = component_register
})

export { component_register }
