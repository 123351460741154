import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-441c41a7"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return true
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["q-mt-md q-mb-xl bg-accent column align-center justify-center p-hero-hover", {
                'q-mx-lg': _ctx.$q.screen.gt.sm,
                'q-mx-md': _ctx.$q.screen.lt.md
            }]),
        style: _normalizeStyle(`
                width: 100%;
                border-radius: 20px;
                height: ${_ctx.$q.screen.lt.md ? '30vh' : '60vh'};
                max-height: 600px;
                position: relative;
                overflow: hidden`)
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["video_overlay", {
                        'video_overlay_sm': _ctx.$q.screen.lt.md
                    }])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["title_container", {
                            'q-pa-xl': _ctx.$q.screen.gt.sm,
                            'q-pa-lg': _ctx.$q.screen.lt.md && _ctx.$q.screen.gt.xs,
                            'q-pa-md': _ctx.$q.screen.lt.sm
                        }])
          }, [
            _createElementVNode("h1", {
              class: _normalizeClass(["headline", {
                                'headline_sm': _ctx.$q.screen.lt.md
                            }])
            }, _toDisplayString(_ctx.data.headline), 3),
            _createElementVNode("h2", {
              class: _normalizeClass(["subline", {
                                'subline_sm': _ctx.$q.screen.lt.md
                            }])
            }, _toDisplayString(_ctx.data.subline), 3)
          ], 2)
        ], 2),
        (_ctx.$q.screen.gt.xs)
          ? (_openBlock(), _createElementBlock("video", {
              key: 0,
              class: _normalizeClass({
                        'hero_video': _ctx.$q.screen.width >= 560,
                        'hero_video2': _ctx.$q.screen.width < 560
                    }),
              autoplay: "",
              muted: "",
              loop: ""
            }, [
              _createElementVNode("source", {
                src: (_ctx.data.background || [{ url: '' }])[0].url || '',
                type: "video/mp4"
              }, null, 8, _hoisted_1)
            ], 2))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "column items-start justify-end",
              title: (_ctx.data.background || [{ alt: '' }])[0].alt || '',
              style: _normalizeStyle(`
                        overflow: hidden;
                        border-radius: 20px;
                        background-size: cover;
                        background-position: ${_ctx.$cms_to_page_focus((_ctx.data.background || [{ focusX: 0 }])[0].focusX || 0, (_ctx.data.background || [{ focusY: 0 }])[0].focusY || 0)};
                        height: 100%;
                        background-image: url(
                            ${(_ctx.data.backgroundImage || [{ url: '' }])[0].url || ''}${
                                (_ctx.data.backgroundImage || [{ imageQuality: 0 }])[0].imageQuality ?
                                '?quality=' + (_ctx.data.backgroundImage || [{ imageQuality: 0 }])[0].imageQuality :
                                ''
                            }
                        )`)
            }, null, 12, _hoisted_2))
      ], 6))
    : _createCommentVNode("", true)
}