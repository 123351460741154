import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "row justify-center wrap q-gutter-lg full-width"
}
const _hoisted_2 = {
  key: 0,
  class: "row justify-center q-mt-md",
  style: {"width":"100%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_timeline = _resolveComponent("q-timeline")!
  const _component_p_products_element = _resolveComponent("p-products-element")!
  const _component_p_cta = _resolveComponent("p-cta")!

  return (['products'].includes(_ctx.type))
    ? (_openBlock(), _createBlock(_component_p_products_element, {
        key: 0,
        headline: _ctx.data.title,
        link: _ctx.$utf8.encode(_ctx.data.title !== null ? _ctx.data.title : '')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_timeline, { color: "primary" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.content || [], (element) => {
                return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$component_register(element.__typename)), {
                  key: element,
                  data: element
                }, null, 8, ["data"]))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["headline", "link"]))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.content || [], (element) => {
          return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$component_register(element.__typename)), {
            key: element,
            data: element
          }, null, 8, ["data"]))
        }), 128)),
        ((_ctx.data.cta || [{ data:{ __typename: '' } }])[0].data.__typename !== '')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_p_cta, {
                type: _ctx.type,
                data: (_ctx.data.cta || [{ data:{ __typename: '' } }])[0].data
              }, null, 8, ["type", "data"])
            ]))
          : _createCommentVNode("", true)
      ]))
}