import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row items-start no-wrap" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_icon, {
      name: Object.values((_ctx.data.icon || [])[0].data.name)[1],
      color: Object.values((_ctx.data.icon || [])[0].data.color)[1],
      size: "sm",
      class: "q-mr-sm"
    }, null, 8, ["name", "color"]),
    _createElementVNode("div", {
      class: "text-text",
      style: {"margin-top":"1px"},
      innerHTML: _ctx.data.description
    }, null, 8, _hoisted_2)
  ]))
}