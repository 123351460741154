/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */

import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client/core'
import { createApolloProvider } from '@vue/apollo-option'
import { DollarApollo } from '@vue/apollo-option/types/vue-apollo'
import fetch from 'cross-fetch'
import { boot } from 'quasar/wrappers'

const API_KEY_RAW = process.env.P_API_KEY || ''
const API_KEY_ARRAY = API_KEY_RAW.split('"')
const API_KEY = API_KEY_ARRAY.length > 1 ? API_KEY_ARRAY[1] : API_KEY_ARRAY[0]

const cache = new InMemoryCache()
const apolloClient = new ApolloClient({
    link: new HttpLink({
        fetch,
        uri     : 'https://cms.pagisto.com/api/content/nordzypern-top-invest/graphql',
        headers : {
            Authorization: `Bearer ${API_KEY}`
        }
    }),
    cache,
    defaultOptions: {
        query: {
            fetchPolicy : 'cache-first',
            errorPolicy : 'all'
        }
    }
})


const apolloProvider = createApolloProvider({
    defaultClient: apolloClient

})

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $apollo: DollarApollo<this>
    }
}

export default boot(({ app }) => {
    app.use(apolloProvider)
})

export { apolloProvider }
