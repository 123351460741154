
import { defineComponent } from 'vue'
import { CMS_COMPONENT_Pagestimeline } from '@/types/cms/pages'

// components
import Basic_Products_Element from '@/components/layout/Basic_Products_Element.vue'
import CTA from '@/components/pages/CTA.vue'


export default defineComponent({
    name: 'p_components_pages_timeline',

    components: {
        'p-products-element' : Basic_Products_Element,
        'p-cta'              : CTA
    },

    props: {
        data: {
            default: new CMS_COMPONENT_Pagestimeline()
        },
        type: {
            default: ''
        }
    }
})
