import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ec1f2db6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_component_q_card, {
    class: "bg-accent p-card row justify-center no-shadow",
    style: `overflow: hidden; border-radius: 20px`
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_img, {
        style: `
                overflow: hidden;
                border-radius: 20px 20px 0 0;
            `,
        width: "100%",
        height: "250px",
        position: _ctx.$cms_to_page_focus((_ctx.data.image || [{ focusX: 0 }])[0].focusX || 0, (_ctx.data.image || [{ focusY: 0 }])[0].focusY || 0),
        src: `
                ${(_ctx.data.image || [{ url: '' }])[0].url || ''}${
                    (_ctx.data.image || [{ imageQuality: 0 }])[0].imageQuality ?
                    '?quality=' + (_ctx.data.image || [{ imageQuality: 0 }])[0].imageQuality :
                    ''
                }
            `,
        alt: (_ctx.data.image || [{ alt: '' }])[0].alt || ''
      }, null, 8, ["position", "src", "alt"]),
      _createElementVNode("div", {
        class: "q-pa-lg text-text",
        innerHTML: _ctx.data.content
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }))
}