import { boot } from 'quasar/wrappers'
import { apolloProvider } from '@/boot/apollo'
import { API_Query_Data, API_Query_Data_Helper, API_Query_Language_Data_Helper } from '@/types/API'
import gql from 'graphql-tag'
import { get_valid_locale } from '@/boot/i18n'
import { CMS_SCHEMA_Pages } from '@/types/cms/pages'
import { CMS_COMPONENT_Seo } from '@/types/cms/components/seo'
import { CMS_SCHEMA_Homepage } from '@/types/cms/homepage'
import meta_config from '@/meta_config'
import { CMS_COMPONENT_Ref_Content } from '@/types/cms/components/ref_content'
import cryptojs from 'crypto-js'

const route_depth = (data: CMS_SCHEMA_Pages[], page: CMS_SCHEMA_Pages, lang: string) => {
    let depth = 0
    if (page.routing?.[lang] && page.routing?.[lang].childOfPage !== null) {
        if (page.routing?.[lang].childOfPage?.length === 1 && page.routing?.[lang].childOfPage?.[0].data.routing?.[lang].routeName) {
            const found = data.find(x => (x.routing?.[lang].routeName === page.routing?.[lang].childOfPage?.[0].data.routing?.[lang].routeName))
            if (found && found !== null) {
                depth = depth + 1 + route_depth(data, found, lang)
            }
        }
    }

    return depth
}

export default boot(async ({ router }) => {
    const homepage: API_Query_Data<API_Query_Language_Data_Helper<CMS_SCHEMA_Homepage>> = await apolloProvider.defaultClient.query({
        query: gql`query {
                data:queryHomepageContents {
                    data {
                        seo {
                            de {
                                title
                                description
                                keywords
                                robots
                                ogImage {
                                    url
                                }
                            }
                            en {
                                title
                                description
                                keywords
                                robots
                                ogImage {
                                    url
                                }
                            }
                        }
                    }
                }
            }`,
        fetchPolicy: 'cache-first'
    })

    const pages: API_Query_Data<API_Query_Data_Helper<CMS_SCHEMA_Pages>> = await apolloProvider.defaultClient.query({
        query: gql`query {
                data:queryPagesContents {
                    id
                    data {
                        name {
                            iv
                        }
                        refContent {
                            de {
                                label
                                labelShort
                                description
                                descriptionShort
                                image {
                                    url
                                    pixelWidth:metadata(path: "pixelWidth")
                                    pixelHeight:metadata(path: "pixelHeight")
                                    imageQuality:metadata(path: "imageQuality")
                                    alt:metadata(path: "alt")
                                    focusX:metadata(path: "focusX")
                                    focusY:metadata(path: "focusY")
                                }
                            }
                            en {
                                label
                                labelShort
                                description
                                descriptionShort
                                image {
                                    url
                                    pixelWidth:metadata(path: "pixelWidth")
                                    pixelHeight:metadata(path: "pixelHeight")
                                    imageQuality:metadata(path: "imageQuality")
                                    alt:metadata(path: "alt")
                                    focusX:metadata(path: "focusX")
                                    focusY:metadata(path: "focusY")
                                }
                            }
                        }
                        routing {
                            de {
                                slug
                                routeName
                                redirectPath
                                childOfPage {
                                    data {
                                        routing {
                                            de {
                                                routeName
                                            }
                                            en {
                                                routeName
                                            }
                                        }
                                    }
                                }
                                password
                            }
                            en {
                                slug
                                routeName
                                redirectPath
                                childOfPage {
                                    data {
                                        routing {
                                            de {
                                                routeName
                                            }
                                            en {
                                                routeName
                                            }
                                        }
                                    }
                                }
                                password
                            }
                        }
                        vueComponent {
                            de
                            en
                        }
                        seo {
                            de {
                                title
                                description
                                keywords
                                robots
                                ogImage {
                                    url
                                }
                            }
                            en {
                                title
                                description
                                keywords
                                robots
                                ogImage {
                                    url
                                }
                            }
                        }
                    }
                }
            }`,
        fetchPolicy: 'cache-first'
    })

    const languages = ['de', 'en']
    const router_obj: {
            [key: string]: CMS_SCHEMA_Pages[]
        } = { }
    const home_obj: {
            [key: string]: CMS_SCHEMA_Homepage[]
        } = { }

    pages.data.data.map((x, index) => {
        const d = x.data
        languages.map(y => {
            if (!router_obj[y] || index === 0) router_obj[y] = []
            const data = Object.assign({ id: x.id }, d)
            router_obj[y].push(data)
        })
    })

    homepage.data.data.map((x, index) => {
        const d = x.data
        languages.map(y => {
            if (!home_obj[y] || index === 0) home_obj[y] = []

            home_obj[y].push(d)
        })
    })

    languages.map(x => {
        const data = router_obj[x]
        router_obj[x].sort((a, b) => (route_depth(data, a, x) - route_depth(data, b, x)))
        home_obj[x].map(d => {
            if (
                d.seo &&
                d.seo[x]
            ) {
                const locale = get_valid_locale(x)
                const type = 'main'
                const route_name = `${type}_${locale}`
                const page_seo = d.seo && d.seo[x] ? d.seo[x] : new CMS_COMPONENT_Seo()
                const route_route_name = `${route_name}_root`
                router.addRoute(
                    'root', {
                        name : route_route_name,
                        path : `/${locale.toLowerCase()}`,
                        meta : {
                            page_seo,
                            page_ref_content : new CMS_COMPONENT_Ref_Content(),
                            lang             : locale.toLowerCase(),
                            type,
                            label            : 'homepage',
                            parent           : 'root'
                        },
                        redirect: {
                            name: route_name
                        },
                        component: () => import('layouts/Main.vue')
                    }
                )

                router.addRoute(
                    route_route_name, {
                        name : route_name,
                        path : '',
                        meta : {
                            page_seo,
                            page_ref_content : new CMS_COMPONENT_Ref_Content(),
                            lang             : locale.toLowerCase(),
                            type,
                            label            : 'homepage',
                            parent           : route_route_name
                        },
                        component: () => import('pages/Index.vue')
                    }
                )

                if (route_name === 'main_de-de') {
                    meta_config.title = page_seo.title,
                    meta_config.meta.description = { name: 'description', content: page_seo.description },
                    meta_config.meta.keywords = { name: 'keywords', content: page_seo.keywords.join(',') }
                }
            }
        })
    })

    languages.map(x => {
        router_obj[x].map(d => {
            const locale = get_valid_locale(x)
            let parent = `main_${locale}_root`
            if (
                d.routing &&
                    d.routing[x] !== null &&
                    d.seo &&
                    d.seo[x] &&
                    d.refContent &&
                    d.refContent[x] &&
                    d.vueComponent &&
                    d.vueComponent[x]
            ) {
                const r = d.routing[x]
                if (
                    r.childOfPage &&
                        r.childOfPage?.length === 1 &&
                        r.childOfPage?.[0].data.routing &&
                        r.childOfPage?.[0].data.routing?.[x] &&
                        r.childOfPage?.[0].data.routing?.[x].routeName
                ) {
                    parent = r.childOfPage?.[0].data.routing?.[x].routeName || `main_${locale}`
                }
                const redirect_path = (d.routing[x].redirectPath || '').toLowerCase()
                router.addRoute(
                    parent, Object.assign({
                        name : r.routeName || '',
                        path : `${(r.slug || '').toLowerCase() || ''}`,
                        meta : {
                            page_seo         : d.seo && d.seo[x] ? d.seo[x] : new CMS_COMPONENT_Seo(),
                            page_ref_content : d.refContent && d.refContent[x] ? d.refContent[x] : new CMS_COMPONENT_Ref_Content(),
                            lang             : locale.toLowerCase(),
                            type             : `page_${d.routing.en.routeName ? d.routing.en.routeName : ''}`,
                            label            : d.name && d.name.iv ? d.name.iv : '',
                            parent,
                            is_protected     : d.routing[x].password !== '' && d.routing[x].password !== null,
                            password         : cryptojs.SHA512(d.routing[x].password || '').toString(),
                            id               : d.id
                        },
                        component: () => import(`pages/${d.vueComponent?.[x] || ''}.vue`)
                    },
                    redirect_path !== null ? {
                        redirect: redirect_path || ''
                    } : { }
                    )
                )
            }
        })
    })
})

