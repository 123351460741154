import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-329e8b66"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "q-pa-lg column justify-evenly items-start no-wrap",
  style: {"z-index":"1","height":"100%"}
}
const _hoisted_2 = { class: "text-h6 text-weight-bold" }
const _hoisted_3 = { style: {"text-align":"left"} }
const _hoisted_4 = ["alt", "title", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: { name: Object.values(_ctx.data.routing || { de: '' })[1].routeName }
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, {
        style: _normalizeStyle(`
                overflow: hidden;
                max-height: 250px;
                min-height: 220px;
                width: ${ _ctx.$q.screen.gt.xs ? _ctx.$q.screen.width > 1870 ? '580px' : _ctx.$q.screen.width > 1600 ? '500px' : '450px' : '100%' };
                border-radius: 20px;
                position: relative;
            `),
        class: "row no-wrap justify-start items-center bg-accent p-card text-text no-shadow"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, _toDisplayString(Object.values(_ctx.data.refContent || { de: '' })[1].label), 1),
            _createElementVNode("div", {
              style: _normalizeStyle(`max-width: ${_ctx.$q.screen.gt.sm ? '65%' : '100%'}`),
              class: "text-body2 q-mt-md"
            }, _toDisplayString(Object.values(_ctx.data.refContent || { de: '' })[1].descriptionShort), 5),
            (!Object.values(_ctx.data.refContent || { de: '' })[1].commingSoon)
              ? (_openBlock(), _createBlock(_component_q_btn, {
                  key: 0,
                  class: "q-mt-md",
                  flat: "",
                  to: { name: Object.values(_ctx.data.routing || { de: '' })[1].routeName },
                  dense: "",
                  "no-caps": "",
                  "aria-label": _ctx.$t('app.learn_more_about', { x: Object.values(_ctx.data.refContent || { de: '' })[1].label }),
                  padding: _ctx.$q.screen.gt.sm ? 'none' : 'sm',
                  color: _ctx.$q.dark.isActive ? 'tertiary' : 'pagisto'
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('app.learn_more_about', { x: Object.values(_ctx.data.refContent || { de: '' })[1].label })), 1),
                    (_ctx.$q.screen.gt.sm)
                      ? (_openBlock(), _createBlock(_component_q_icon, {
                          key: 0,
                          name: "chevron_right"
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["to", "aria-label", "padding", "color"]))
              : (_openBlock(), _createBlock(_component_q_btn, {
                  key: 1,
                  disable: "",
                  class: "q-mt-md",
                  flat: "",
                  color: "negative",
                  dense: "",
                  ripple: false,
                  "no-caps": "",
                  padding: "none",
                  "aria-label": _ctx.$t('app.main.products.comming_soon.label'),
                  label: _ctx.$t('app.main.products.comming_soon.label')
                }, null, 8, ["aria-label", "label"]))
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["absolute", {
                    'transparent_image': _ctx.$q.screen.lt.sm
                }]),
            style: {"right":"0","top":"0","height":"100%"}
          }, [
            _createElementVNode("img", {
              alt: Object.values(_ctx.data.refContent || { image: [{ alt: '' }] })[1].image[0].alt || '',
              title: Object.values(_ctx.data.refContent || { image: [{ alt: '' }] })[1].image[0].alt || '',
              loading: "lazy",
              height: "250",
              width: "375",
              src: `${(Object.values(_ctx.data.refContent || { image: [{ url: '' }] })[1].image[0].url || '').length > 0 ? (Object.values(_ctx.data.refContent || { image: [{ url: '' }] })[1].image[0].url || '') : '' }`
            }, null, 8, _hoisted_4)
          ], 2)
        ]),
        _: 1
      }, 8, ["style"])
    ]),
    _: 1
  }, 8, ["to"]))
}