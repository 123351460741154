import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dc3721ec"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_component_q_card, {
    class: "q-pa-lg bg-accent p-card column justify-start no-shadow",
    style: `overflow: hidden; border-radius: 20px`
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_img, {
        loading: "lazy",
        class: "q-mb-md",
        width: "75px",
        height: "75px",
        alt: (_ctx.data.icon || [{ alt: '' }])[0].alt || '',
        src: `
                ${(_ctx.data.icon || [{ url: '' }])[0].url || ''}${
                    (_ctx.data.icon || [{ imageQuality: 0 }])[0].imageQuality ?
                    '?quality=' + (_ctx.data.icon || [{ imageQuality: 0 }])[0].imageQuality :
                    ''
                }
            `
      }, null, 8, ["alt", "src"]),
      _createElementVNode("div", {
        class: "text-text",
        innerHTML: _ctx.data.content
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }))
}