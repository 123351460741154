// This is just an example,
// so you can safely delete all default props below

export default {
    defaults: {
        languages: {
            english : 'English',
            german  : 'German'
        },
        sign_in: 'Start now'
    },

    not_found_error: {
        title   : 'Error 404',
        message : {
            content : 'Oh, seems like there is nothing here. Weird.',
            author  : 'Pagistoni'
        },
        try_again: 'Go Back'
    },

    app: {
        footer: {
            imprint        : 'Impressum',
            privacy        : 'Privacy Policy',
            terms          : 'terms & conditions',
            legal_message1 : '© 2021 Pagisto GmbH. All rights reserved',
            legal_message2 : 'Please be Aware: This is a product by the Pagisto GmbH in cooperation with franchisees of the BNI.'
        },

        learn_more_about: 'Learn more about {x}',

        main: {
            products: {
                comming_soon: {
                    label: 'Comming soon'
                }
            }
        },

        pages: {
            editor: {
                visit          : 'Visit {x} >',
                system_partner : 'System partner {x}'
            }
        },

        cookie_warning: {
            buttons: {
                decline          : 'Decline',
                accept_all       : 'Accept All',
                accept_selection : 'Accept selected',
                more             : 'Cookie Settings',
                back             : 'Back'
            }
        }
    }
}
