import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5acb3848"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createElementBlock("a", {
    href: _ctx.data.link,
    target: "__blank",
    style: {"text-decoration":"none"}
  }, [
    _createVNode(_component_q_card, {
      class: "q-pa-lg bg-accent p-card row justify-start items-center",
      style: `overflow: hidden; border-radius: 20px`
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createVNode(_component_q_icon, {
            size: "lg",
            color: _ctx.$q.dark.isActive ? 'text' : 'primary',
            name: "ios_share"
          }, null, 8, ["color"])
        ]),
        _createElementVNode("div", {
          class: "q-px-md text-text",
          style: {"flex":"2","word-break":"normal"},
          innerHTML: _ctx.data.content
        }, null, 8, _hoisted_2),
        _createElementVNode("div", null, [
          _createVNode(_component_q_icon, {
            size: "lg",
            color: _ctx.$q.dark.isActive ? 'text' : 'primary',
            name: "chevron_right"
          }, null, 8, ["color"])
        ])
      ]),
      _: 1
    })
  ], 8, _hoisted_1))
}