
import { defineComponent } from 'vue'
import { CMS_COMPONENT_Pageslistgridcombi } from '@/types/cms/pages'

// components
import Basic_Products_Element from '@/components/layout/Basic_Products_Element.vue'
import CTA from '@/components/pages/CTA.vue'


export default defineComponent({
    name: 'p_components_pages_list_grid_combi',

    data () {
        return {
            show_headline_link_symbol: false
        }
    },

    components: {
        'p-products-element' : Basic_Products_Element,
        'p-cta'              : CTA
    },

    props: {
        data: {
            default: new CMS_COMPONENT_Pageslistgridcombi()
        },
        type: {
            default: ''
        }
    },

    methods: {
        async p_click_on_headline (value: string) {
            if (value !== null && value !== '') {
                this.$router.push(`#${value}`)
                this.show_headline_link_symbol = true

                const domain = 'https://nordzypern-top-invest.de/!'
                const full_url = `${domain}${this.$route.fullPath}`

                await navigator.clipboard.writeText(full_url)
            }
        }
    },

    watch: {
        '$route.hash': {
            handler (new_value: string) {
                const link = this.data.title || '#'
                if (new_value !== `#${link}`) {
                    this.show_headline_link_symbol = false
                }
            },
            immediate: true
        }
    },

    mounted () {
        const link = this.data.title || '#'
        if (this.$route.hash === `#${link}`) {
            this.show_headline_link_symbol = true
        }
    }
})
