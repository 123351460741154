import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = {
  key: 0,
  class: "row justify-center q-mt-xl",
  style: {"width":"100%"}
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 0,
  class: "row justify-center q-mt-xl",
  style: {"width":"100%"}
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 0,
  class: "row justify-center q-mt-xl",
  style: {"width":"100%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_p_cta = _resolveComponent("p-cta")!
  const _component_p_products_element = _resolveComponent("p-products-element")!
  const _component_p_legals_element = _resolveComponent("p-legals-element")!

  return (['products'].includes(_ctx.type))
    ? (_openBlock(), _createBlock(_component_p_products_element, {
        key: 0,
        headline: _ctx.data.title ? _ctx.data.title : '',
        link: _ctx.$utf8.encode(_ctx.data.title !== null ? _ctx.data.title : '')
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: "text-text",
            innerHTML: _ctx.data.content
          }, null, 8, _hoisted_1),
          ((_ctx.data.cta || [{ data:{ __typename: '' } }])[0].data.__typename !== '')
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_p_cta, {
                  type: _ctx.type,
                  data: (_ctx.data.cta || [{ data:{ __typename: '' } }])[0].data
                }, null, 8, ["type", "data"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["headline", "link"]))
    : (['legal'].includes(_ctx.type))
      ? (_openBlock(), _createBlock(_component_p_legals_element, {
          key: 1,
          headline: _ctx.data.title ? _ctx.data.title : '',
          link: _ctx.$utf8.encode(_ctx.data.title !== null ? _ctx.data.title : '')
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass([{
                    p_legal_content: _ctx.$q.screen.gt.sm,
                    p_legal_content_mobile: _ctx.$q.screen.lt.md
                }, "text-text"]),
              innerHTML: _ctx.data.content
            }, null, 10, _hoisted_3),
            ((_ctx.data.cta || [{ data:{ __typename: '' } }])[0].data.__typename !== '')
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_p_cta, {
                    type: _ctx.type,
                    data: (_ctx.data.cta || [{ data:{ __typename: '' } }])[0].data
                  }, null, 8, ["type", "data"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["headline", "link"]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          _createElementVNode("div", {
            class: "text-text",
            innerHTML: _ctx.data.content
          }, null, 8, _hoisted_5),
          ((_ctx.data.cta || [{ data:{ __typename: '' } }])[0].data.__typename !== '')
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_p_cta, {
                  type: _ctx.type,
                  data: (_ctx.data.cta || [{ data:{ __typename: '' } }])[0].data
                }, null, 8, ["type", "data"])
              ]))
            : _createCommentVNode("", true)
        ], 64))
}