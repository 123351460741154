import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row items-center justify-start no-wrap" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_img = _resolveComponent("q-img")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_img, {
      alt: (_ctx.data.image || [{ alt: '' }])[0].alt || '',
      src: (_ctx.data.image || [])[0].url,
      position: _ctx.$cms_to_page_focus((_ctx.data.image || [{ focusX: 0 }])[0].focusX || 0, (_ctx.data.image || [{ focusY: 0 }])[0].focusY || 0),
      "spinner-color": "white",
      style: {"height":"75px","max-width":"75px","border-radius":"10px"}
    }, null, 8, ["alt", "src", "position"]),
    _createElementVNode("div", {
      class: "text-text q-ml-md",
      style: {"margin-top":"1px"},
      innerHTML: _ctx.data.description
    }, null, 8, _hoisted_2)
  ]))
}