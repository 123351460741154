import { route } from 'quasar/wrappers'
import {
    createMemoryHistory,
    createRouter,
    createWebHashHistory,
    createWebHistory
} from 'vue-router'
import routes from './routes'
import { CMS_COMPONENT_Seo } from '@/types/cms/components/seo'
import { STATE_Root } from '@/store'
import { CMS_COMPONENT_Ref_Content } from '@/types/cms/components/ref_content'

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

declare module 'vue-router' {
    interface RouteMeta {
        page_seo: CMS_COMPONENT_Seo
        page_ref_content: CMS_COMPONENT_Ref_Content
        type?: string
        lang?: string
        parent?: string
        id?: string
        is_protected?: boolean
        password?: string
    }
}

export default route<STATE_Root>(function (/* { store, ssrContext } */) {
    const createHistory = process.env.SERVER
        ? createMemoryHistory
        : (process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory)

    const Router = createRouter({
        scrollBehavior: (to, from) => {
            /* if (savedPosition) {
                return savedPosition
            } */

            if (to.hash && to.hash.length > 0) {
                let timeout = 50
                if (!from.name || from.name === null || from.name === '') {
                    timeout = 400
                }

                return new Promise((resolve) => {
                    setTimeout(() => {
                        const name = typeof to.name === 'string' ? to.name : ''
                        const top = name.includes('main_') ? 98 + 50 : 98

                        resolve({
                            el       : to.hash,
                            behavior : 'smooth',
                            top
                        })
                    }, timeout)
                })
            } else {
                if (!from.name || from.name === null || from.name === '') {
                    return {
                        top  : 0,
                        left : 0
                    }
                }
                return new Promise((resolve) => {
                    setTimeout(() => {
                        resolve({
                            top      : 0,
                            left     : 0,
                            behavior : 'smooth'
                        })
                    }, 50)
                })
            }
        },
        routes,
        history: createHistory(
            process.env.MODE === 'ssr' ? void 0 : process.env.VUE_ROUTER_BASE
        )
    })

    return Router
})
