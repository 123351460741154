import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-574c9816"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { class: "q-mt-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_p_cta = _resolveComponent("p-cta")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle([`
            border-radius: 20px;
            width: calc(${ _ctx.$q.screen.gt.sm ? '100% - 48px' : '100% - 32px'});
            ${ _ctx.$q.screen.gt.sm ? 'padding-left: 10%; padding-right: 10%' : ''}
        `, {"margin-bottom":"9rem"}]),
    class: _normalizeClass({
            'row justify-between': _ctx.$q.screen.gt.sm,
            'column': _ctx.$q.screen.lt.md
        })
  }, [
    _createElementVNode("div", {
      class: "relative-position",
      style: _normalizeStyle(`max-width: ${ _ctx.$q.screen.gt.sm ? '40%' : '100%' }; flex: 1`)
    }, [
      (_ctx.data.title && _ctx.data.title !== null && _ctx.data.title.length > 0)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.p_click_on_headline(_ctx.data.title))),
            class: "q-pa-sm p_hover_parent",
            style: `
                    position: relative;
                    cursor: pointer`
                
          }, [
            _createElementVNode("div", {
              class: "p_code",
              style: _normalizeStyle(`
                        font-size: ${ _ctx.$q.screen.gt.sm ? '2rem' : '1.5rem' };
                        line-height: 2rem;
                        font-weight: 700;
                        text-align: left;
                        width: 100%;
                        text-align: ${ _ctx.$q.screen.gt.sm ? 'left' : 'center' };
                    `)
            }, _toDisplayString(_ctx.data.title), 5),
            _withDirectives(_createVNode(_component_q_icon, {
              size: "md",
              style: _normalizeStyle(`
                        position: absolute;
                        top: 50%;
                        right: ${ _ctx.$q.screen.gt.sm ? '0' : '30px' };
                        transform: translate3d(33%, -50%, 0) rotateZ(45deg);
                    `),
              name: "link",
              color: "primary"
            }, null, 8, ["style"]), [
              [_vShow, _ctx.show_headline_link_symbol]
            ]),
            (!_ctx.show_headline_link_symbol)
              ? (_openBlock(), _createBlock(_component_q_icon, {
                  key: 0,
                  size: "md",
                  class: "p_hover",
                  style: _normalizeStyle(`
                        position: absolute;
                        top: 50%;
                        right: ${ _ctx.$q.screen.gt.sm ? '0' : '30px' };
                        transform: translate3d(33%, -50%, 0) rotateZ(45deg);
                    `),
                  name: "add_link",
                  color: "primary"
                }, null, 8, ["style"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: "q-mt-lg",
        style: {"line-height":"200%","text-align":"left"},
        innerHTML: _ctx.data.description
      }, null, 8, _hoisted_1),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.list || [], (element) => {
          return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$component_register(element.__typename)), {
            key: element,
            class: "q-my-md",
            style: {"max-width":"100%"},
            data: element
          }, null, 8, ["data"]))
        }), 128))
      ]),
      ((_ctx.data.cta || [{ data:{ __typename: '' } }])[0].data.__typename !== '')
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["row q-mt-xl", {
                    'justify-center q-mb-lg': _ctx.$q.screen.lt.md,
                    'justify-start': _ctx.$q.screen.gt.sm
                }]),
            style: {"width":"100%"}
          }, [
            _createVNode(_component_p_cta, {
              type: _ctx.type,
              data: (_ctx.data.cta || [{ data:{ __typename: '' } }])[0].data
            }, null, 8, ["type", "data"])
          ], 2))
        : _createCommentVNode("", true)
    ], 4),
    _createElementVNode("div", {
      class: "relative-position row justify-end",
      style: _normalizeStyle(`max-width: ${ _ctx.$q.screen.gt.sm ? '60%' : '100%' }; flex: 1`)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.grid || [], (element) => {
        return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$component_register(element.__typename)), {
          key: element,
          class: _normalizeClass(["p-element", {
                        'q-ma-md': _ctx.$q.screen.gt.sm,
                        'q-my-md': _ctx.$q.screen.lt.md
                    }]),
          style: _normalizeStyle(`
                        max-width: ${_ctx.$q.screen.gt.sm ? '44.3%' : '100%'};
                        min-width: 250px;
                        width: 100%;
                    `),
          data: element
        }, null, 8, ["class", "style", "data"]))
      }), 128))
    ], 4)
  ], 6))
}