import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-19549f75"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!

  return (_ctx.$q.screen.gt.sm)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        id: _ctx.link,
        style: {"width":"100%","margin-bottom":"9rem"},
        class: "column items-center"
      }, [
        (_ctx.headline !== null && _ctx.headline.length > 0)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.p_click_on_headline(_ctx.link))),
              class: "q-mx-lg bg-accent2 max-width: 50%; q-pa-sm p_hover_parent",
              style: _normalizeStyle(`
                    border-radius: 20px;
                    max-width: ${ _ctx.$q.screen.gt.sm ? 50 : 80 }%;
                    position: relative;
                    cursor: pointer`
                )
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["p_code", {
                        'text-pagisto': !_ctx.$q.dark.isActive,
                        'text-tertiary': _ctx.$q.dark.isActive
                    }]),
                style: {"font-size":"2rem","line-height":"2rem","font-weight":"700","text-align":"center"}
              }, _toDisplayString(_ctx.headline), 3),
              _withDirectives(_createVNode(_component_q_icon, {
                size: "md",
                style: {"position":"absolute","top":"50%","right":"0","transform":"translate3d(125%, -50%, 0) rotateZ(45deg)"},
                name: "link",
                color: "primary"
              }, null, 512), [
                [_vShow, _ctx.show_headline_link_symbol]
              ]),
              (!_ctx.show_headline_link_symbol)
                ? (_openBlock(), _createBlock(_component_q_icon, {
                    key: 0,
                    size: "md",
                    class: "p_hover",
                    style: {"position":"absolute","top":"50%","right":"0","transform":"translate3d(125%, -50%, 0) rotateZ(45deg)"},
                    name: "add_link",
                    color: "primary"
                  }))
                : _createCommentVNode("", true)
            ], 4))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(["q-mt-md relative-position", {
                    'q-mx-lg q-py-md': !_ctx.custom_padding
                }]),
          style: _normalizeStyle(`
                    border-radius: 20px;
                    width: calc(${ _ctx.custom_padding ? '100%' : '100% - 48px' });
                    ${!_ctx.custom_padding ? 'padding-left: 10%; padding-right: 10%' : ''}
                `)
        }, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 6)
      ], 8, _hoisted_1))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        id: _ctx.link,
        style: {"width":"100%","margin-bottom":"4rem"},
        class: "column items-center"
      }, [
        (_ctx.headline !== null && _ctx.headline.length > 0)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.p_click_on_headline(_ctx.link))),
              class: "q-mx-lg bg-accent2 q-pa-sm p_hover_parent",
              style: _normalizeStyle(`
                    border-radius: 20px;
                    max-width: ${ _ctx.$q.screen.gt.sm ? 50 : 80 }%;
                    position: relative;
                    cursor: pointer`
                )
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["p_code", {
                        'text-pagisto': !_ctx.$q.dark.isActive,
                        'text-tertiary': _ctx.$q.dark.isActive
                    }]),
                style: {"font-size":"1.5rem","line-height":"2rem","font-weight":"700","text-align":"center"}
              }, _toDisplayString(_ctx.headline), 3),
              _withDirectives(_createVNode(_component_q_icon, {
                size: "md",
                style: {"position":"absolute","top":"50%","right":"0","transform":"translate3d(33%, -50%, 0) rotateZ(45deg)"},
                name: "link",
                color: "primary"
              }, null, 512), [
                [_vShow, _ctx.show_headline_link_symbol]
              ]),
              (!_ctx.show_headline_link_symbol)
                ? (_openBlock(), _createBlock(_component_q_icon, {
                    key: 0,
                    size: "md",
                    class: "p_hover",
                    style: {"position":"absolute","top":"50%","right":"0","transform":"translate3d(33%, -50%, 0) rotateZ(45deg)"},
                    name: "add_link",
                    color: "primary"
                  }))
                : _createCommentVNode("", true)
            ], 4))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(["q-mt-md relative-position", {
                    'q-mx-lg q-py-md': !_ctx.custom_padding && _ctx.$q.screen.gt.sm,
                    'q-mx-md q-py-sm': !_ctx.custom_padding && _ctx.$q.screen.lt.md,
                }]),
          style: _normalizeStyle(`
                    border-radius: 20px;
                    width: calc(${ _ctx.custom_padding ? '100%' : '100% - 32px' });
                `)
        }, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 6)
      ], 8, _hoisted_2))
}