import { API_Query_Asset } from '../API'

export class CMS_SCHEMA_Grid_Element_Text {
    content?: string
    __typename?: string
}

export class CMS_SCHEMA_Grid_Element_Download {
    content?: string
    file?: API_Query_Asset[]
    __typename?: string

    constructor () {
        this.content = ''
        this.file = []
        this.__typename = ''
    }
}

export class CMS_SCHEMA_Grid_Element_Link {
    content?: string
    link?: string
    __typename?: string

    constructor () {
        this.content = ''
        this.link = ''
        this.__typename = ''
    }
}

export class CMS_SCHEMA_Grid_Element_Text_Video {
    content?: string
    videos?: API_Query_Asset[]
    __typename?: string

    constructor () {
        this.content = ''
        this.videos = []
        this.__typename = ''
    }
}

export class CMS_SCHEMA_Grid_Element_Text_Icon {
    content?: string
    icon?: API_Query_Asset[]
    __typename?: string

    constructor () {
        this.content = ''
        this.icon = []
        this.__typename = ''
    }
}

export class CMS_SCHEMA_Grid_Element_Text_Image {
    content?: string
    image?: API_Query_Asset[]
    __typename?: string

    constructor () {
        this.content = ''
        this.image = []
        this.__typename = ''
    }
}
