// This is just an example,
// so you can safely delete all default props below

export default {
    defaults: {
        languages: {
            english : 'Englisch',
            german  : 'Deutsch'
        },
        sign_in: 'Jetzt loslegen'
    },

    not_found_error: {
        title   : '404 Fehler',
        message : {
            content : 'Upsi, ich scheine die von dir gesuchte Seite verlegt zu haben. Konzentriere dich nur auf mich. Hier gibt es nichts zu sehen. Wuhhuuu.',
            author  : 'Pagistoni'
        },
        try_again: 'Zurück'
    },

    app: {
        footer: {
            imprint        : 'Impressum',
            privacy        : 'Datenschutz',
            terms          : 'AGB',
            legal_message1 : '© 2021 Pagisto. Alle Rechte vorbehalten',
            legal_message2 : 'Hinweis: Dies ist ein Produkt der Pagisto GmbH in Zusammenarbeit mit Franchisenehmern von BNI.'
        },

        learn_more_about: 'Lerne mehr über {x}',

        member_area: 'Mitgliederbereich',

        main: {
            products: {
                comming_soon: {
                    label: 'Bald erhältlich'
                }
            }
        },

        pages: {
            editor: {
                visit          : 'Besuche {x} >',
                system_partner : 'Systempartner {x}'
            }
        },

        cookie_warning: {
            buttons: {
                decline          : 'Ablehnen',
                accept_all       : 'Allen Zustimmen',
                accept_selection : 'Ausgewählten zustimmen',
                more             : 'Cookie Einstellungen',
                back             : 'Zurück'
            }
        },

        password_input: {
            buttons: {
                back   : 'Zurück zur Hauptseite',
                submit : 'Log in'
            },
            error    : 'Falsches Passwort',
            title    : 'Mitgliederbereich',
            message  : 'Um diese Seite besuchen zu können ist das nach der Buchung der Reise erhaltene Passwort notwendig.',
            password : 'Passwort*'
        }
    }
}
