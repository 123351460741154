export default {
    titleTemplate : (title: string) => `${title} | Nordzypern Top Invest`,
    title         : 'Nordzypern Top Invest Website',
    // meta tags
    meta          : {
        title: {
            name: 'title',
            template (title: string) {
                return `${title} | Nordzypern Top Invest`
            }
        },
        description : { name: 'description', content: 'APP123' },
        keywords    : { name: 'keywords', content: 'Nordzypern Top Invest' },
        equiv       : { 'http-equiv': 'Content-Type', content: 'text/html; charset=UTF-8' },

        // note: for Open Graph type metadata you will need to use SSR, to ensure page is rendered by the server
        ogTitle: {
            property: 'og:title',
            // optional; similar to titleTemplate, but allows templating with other meta properties
            template (ogTitle: string) {
                return `${ogTitle} | Nordzypern Top Invest`
            }
        },
        ogType        : { property: 'og:type', content: 'website' },
        ogDescription : { property: 'og:description', content: 'website' },
        ogUrl         : {
            property: 'og:url',
            template (ogUrl: string) {
                return `https://${'www.nordzypern-top-invest.de/!'}${ogUrl}`
            }
        },
        ogImage: { property: 'og:image', content: '' },

        // twitter
        twitterCard  : { property: 'twitter:card', content: 'summary' },
        twitterTitle : {
            property: 'twitter:title',
            // optional; similar to titleTemplate, but allows templating with other meta properties
            template (ogTitle: string) {
                return `${ogTitle} | Nordzypern Top Invest`
            }
        },
        twitterDescription : { property: 'twitter:description', content: 'website' },
        twitterImage       : { property: 'twitter:image', content: '' },

        robots: { name: 'robots', content: 'index, follow' }
    },

    // CSS tags
    link: {
        material   : { rel: 'stylesheet', href: 'https://fonts.googleapis.com/icon?family=Material+Icons' },
        canonical  : { rel: 'canonical', href: 'https://www.nordzypern-top-invest.de/!/' },
        alternate1 : { rel: 'alternate', hreflang: 'de', href: 'https://www.nordzypern-top-invest.de/!/de-de' },
        alternate2 : { rel: 'alternate', hreflang: 'en', href: 'https://www.nordzypern-top-invest.de/!/en-us' }
    },

    // JS tags
    script: {
        ldJson: {
            type      : 'application/ld+json',
            innerHTML : '{ "@context": "http://schema.org" }'
        }
    },

    // <html> attributes
    htmlAttr: {
        'xmlns:cc' : 'http://creativecommons.org/ns#', // generates <html xmlns:cc="http://creativecommons.org/ns#">,
        empty      : undefined // generates <html empty>
    },

    // <body> attributes
    bodyAttr: {
        'action-scope' : 'xyz', // generates <body action-scope="xyz">
        empty          : undefined // generates <body empty>
    },

    // <noscript> tags
    noscript: {
        default: 'This is content for browsers with no JS (or disabled JS)'
    }
}
