
import { CMS_COMPONENT_Pagesgridlistelementimage } from '@/types/cms/pages'
import { defineComponent } from 'vue'

// components

export default defineComponent({
    name: 'p_components_pages_grid_list_image_element',

    props: {
        data: {
            default: new CMS_COMPONENT_Pagesgridlistelementimage()
        }
    }
})
